import {
    SET_LOCALE, UPDATE_CONNECTION_STATUS, LIST_USER_NOTIFICATIONS, SET_NOTIFICATION_STATUS, PREPEND_NEW_NOTIFICATION, SET_NOTIFICATIONS_COUNTER, SET_UPLOADING_STATUS, SHOW_EDITOR_UPLOADER
    ,UPDATE_EVENT_DURATION,
    LOG_EVENT,
    SHOW_MATH_MODAL,
} from './action-types'
import { en } from '../../../resources/en';
import { ar } from '../../../resources/ar';


/**
 * Module internal initial state
 */
const initialState = {
    currentLocale: null,
    currentResource: null,
    connectionStatus: false,
    currentDirection: null,
    notificationsList: [],
    notificationsCount: 0,
    showEditorUploader: false,
    showMathModal: false,
    uploadingStatus: {
        totalPercentage: 0,
        visible: false,
        filesCount: 0,
    }
};  


/**
 * Check dispatched action and update state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case SET_LOCALE: 
            return setLocale(state, payload)
        case UPDATE_CONNECTION_STATUS: 
            return updateConnectionStatus(state, payload)
        case LIST_USER_NOTIFICATIONS:
            return setNotificationsList(state, payload)
        case SET_NOTIFICATION_STATUS:
            return setNotificationStatus(state, payload)
        case PREPEND_NEW_NOTIFICATION:
            return addNewNotification(state, payload)
        case SET_NOTIFICATIONS_COUNTER:
            return setNotificationsCounter(state, payload)
        case SET_UPLOADING_STATUS:
            return setUploadingStatus(state, payload)
        case LOG_EVENT:
            return logEvent(state, payload)
        case UPDATE_EVENT_DURATION:
            return updateEventDuration(state, payload)
        case SHOW_EDITOR_UPLOADER:
            return showEditorUploader(state, payload)
        case SHOW_MATH_MODAL:
            return showMathModal(state, payload)
        default:
            return state;
    }
} 

function setLocale(state, payload){
    localStorage.setItem('currentLocale', payload)
    return {
        ...state, currentLocale: payload, currentResource: payload === 'english' ? en : ar, currentDirection: payload === 'en' ? 'ltr' : 'rtl'
    }
}

function updateConnectionStatus(state, payload){
    return {
        ...state, connectionStatus: payload
    }
}

function setNotificationsList(state, payload) {
    return {
        ...state, notificationsList: payload.list
    }
}

function setNotificationStatus(state, payload) {
    let index
    let notificationsList = state.notificationsList
    for (const prop in notificationsList) {
        if(prop === payload.key){
            index = notificationsList[prop].findIndex(notification => notification.notificationID === payload.id)
            if(index > -1){
                notificationsList[prop][index].isSeen = true
            }
        }
    }
    return {
        ...state, notificationsList, notificationsCount: state.notificationsCount - 1
    }
}

function addNewNotification(state, payload){
    let notificationsList = state.notificationsList
    for (const date in notificationsList) {
        if(payload.incomingDate === date){
            console.log(date)
        }
    }

    return {
        ...state, notificationsCount: state.notificationsCount + 1
    }
}

function setNotificationsCounter(state, payload) {
    return {
        ...state, notificationsCount: payload
    }   
}

function setUploadingStatus(state, payload) {
    return {
        ...state, uploadingStatus: payload
    }   
}

function showEditorUploader(state, payload) {
    return {
        ...state, showEditorUploader: payload
    }
}

function showMathModal(state, payload) {
    return {
        ...state, showMathModal: true
    }
}

function logEvent(state, payload) {
    let eventIndex = state.events.findIndex(ev => ev.data.id === payload.data.id)
    if(eventIndex === -1){
        window.localStorage.setItem('events', JSON.stringify(state.events))
        return {
            ...state, events: [...state.events, payload]
        }
    }
    else {
        return state
    }
}

function updateEventDuration(state, payload) {
    let eventIndex = state.events.findIndex(ev => ev.data.id === payload)
    let events = state.events
    if(eventIndex > -1){
        events[eventIndex].data.duration++; 
    }
    window.localStorage.setItem('events', JSON.stringify(state.events))
    return {
        ...state, events
    }
}


export default reducer