import React from 'react'
import Loadable from 'react-loadable'
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator'

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const routes = [
    {
        path: '/',
        isAuth: true,
        exact: true,
        component: Loadable({
            loader: () => import('../Teacher'),
            loading: () => (<LoadingIndicator />)
        })
    },
    {
        path: '/classroom/:name',
        isAuth: true,
        exact: true,
        component: Loadable({
            loader: () => import('./views/ClassRoom/ClassRoom'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/classroom/:name/homeworkStudentsList',
        isAuth: true,
        exact: true,
        component: Loadable({
            loader: () => import('./components/ClassHomeworkStudentsList/ClassHomeworkStudentsList'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/onlinesessions',
        isAuth: true,
        exact: true,
        strict: true,
        component: Loadable({
            loader: () => import('./views/OnlineSessions/OnlineSessions'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/exams',
        isAuth: true,
        exact: true,
        strict: true,
        component: Loadable({
            loader: () => import('./views/ExamsList/ExamsList'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/exams/create',
        isAuth: true,
        exact: true,
        component: Loadable({
            loader: () => import('./views/CreateNewExam/CreateNewExam'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/exams/edit/:id',
        isAuth: true,
        exact: true,
        component: Loadable({
            loader: () => import('./views/CreateNewExam/CreateNewExam'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/onlinesessions/summary',
        isAuth: true,
        exact: true,
        component: Loadable({
            loader: () => import('./components/OnlineSessionSummary/OnlineSessionSummary'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/calendar',
        isAuth: true,
        exact: true,
        strict: true,
        component: Loadable({
            loader: () => import('./components/TeacherCalendar/TeacherCalendar'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/courses',
        isAuth: true,
        exact: true,
        strict: true,
        component: Loadable({
            loader: () => import('./components/Courses/Courses'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
    {
        path: '/supervisorcorrecters',
        isAuth: true,
        exact: true,
        strict: true,
        component: Loadable({
            loader: () => import('./components/SupervisorCorrectorsList/SupervisorCorrectorsList'),
            loading: () => (<LoadingIndicator/>)
        }) 
    },
]

export default routes