/**
* Import action types here
*/
import {
    SET_SPECIAL_NOTE_VISIBILITY,
    LIST_CLASSROOMS,
    LIST_STORIES,
    ADD_STORY,
    TOGGLE_FLAG,
    SET_ALLOWED_HOMEWORK_ACTIONS,
    LIST_CLASS_STUDENTS,
    UPDATE_STUDENTS,
    SET_FIREBASE_TOKEN,
    LIST_ALL_NOTIFICATIONS,
    SET_SECTION_TITLE,
    SET_ACTIVE_SUBJECT,
    SET_QUIZ_TYPE,
    SET_STUDENTS_LIST,
    APPEND_INPUT,
    REMOVE_INPUT,
    REMOVE_ALL_INPUTS,
    EDIT_INPUT_VALUE,
    SET_ACTIVE_EDITOR,
    SET_ACTIVE_NAVIGATION_TAB,
    SET_CURRENT_CLASS,
    SET_CURRENT_QUIZ,
    REMOVE_LAST_INPUT,
    SET_QUESTION_TITLE
} from './action-types';
import { v4 as uuidv4 } from 'uuid';


/**
* Module internal initial state
*/
const initialState = {
    isSpecialNoteVisible: false,
    classRooms: [],
    classStudents: [],
    storiesList: [],
    allNotifications: [],
    studentsList: [],
    inputsList: [],
    storiesLastDateTicks: null,
    storiesTotalCount: null,
    allowMultiple: false,
    homeworkAllowedActions: {
        add: false,
        edit: false,
        delete: false,
        view: false,
    },
    showHomeworkModal: false,
    showRemoveHomeworkConfirm: false,
    firebaseToken: null,
    sectionTitle: null,
    activeSubject: null,
    appendedQuiz: null,
    activeEditor: null,
    activeTab: 0,
    currentClass: null,
    currentQuiz: null,
    questionTitle: '',
};


/**
* Checks dispatched actions and updates state accordingly
* @param {Object} state 
* @param {Object} action 
* @returns {Function} 
*/

const reducer = (state = initialState, { type, payload = null, mode }) => {
    switch (type) {
        case SET_SPECIAL_NOTE_VISIBILITY:
            return setSpecialNoteVisibility(state, payload)
        case LIST_CLASSROOMS:
            return classRoomsList(state, payload)
        case LIST_STORIES:
            return listStories(state, payload)
        case ADD_STORY:
            return addStory(state, payload)
        case TOGGLE_FLAG:
            return toggleFlag(state, payload)
        case SET_ALLOWED_HOMEWORK_ACTIONS:
            return setAllowedHomeworkActions(state, payload)
        case LIST_CLASS_STUDENTS:
            return classStudentsList(state, payload)
        case UPDATE_STUDENTS:
            return updateStudents(state, payload)
        case SET_FIREBASE_TOKEN:
            return setFirebaseToken(state, payload)
        case LIST_ALL_NOTIFICATIONS:
            return listNotifications(state, payload)
        case SET_SECTION_TITLE:
            return setSectionTitle(state, payload)
        case SET_ACTIVE_SUBJECT:
            return setActiveSubject(state, payload)
        case SET_QUIZ_TYPE:
            return setQuizType(state, payload, mode)
        case SET_STUDENTS_LIST:
            return setStudentsList(state, payload)
        case APPEND_INPUT:
            return appendInput(state, payload)
        case REMOVE_INPUT:
            return removeInput(state, payload)
        case REMOVE_LAST_INPUT:
            return removeLastInput(state, payload)
        case REMOVE_ALL_INPUTS:
            return removeAllInputs(state)
        case EDIT_INPUT_VALUE:
            return editInputValue(state, payload)
        case SET_ACTIVE_EDITOR:
            return setActiveEditor(state, payload)
        case SET_ACTIVE_NAVIGATION_TAB:
            return setActiveTab(state, payload)
        case SET_CURRENT_CLASS:
            return setCurrentClass(state, payload)
        case SET_CURRENT_QUIZ:
            return setCurrentQuiz(state, payload)
        case SET_QUESTION_TITLE: 
            return setQuestionTitle(state, payload)
        default:
            return state;
    }
}

/**
* Returns an updated version of the state based on the action
* @param {Object} state 
* @param {Object} payload 
* @return {Object} state
*/

function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

function setSpecialNoteVisibility(state, payload) {
    return {
        ...state, isSpecialNoteVisible: payload
    }
}

function classRoomsList(state, payload) {
    return {
        ...state, classRooms: payload
    }
}

function classStudentsList(state, payload) {
    return {
        ...state, classStudents: payload
    }
}

function listStories(state, payload) {
    return {
        ...state, storiesList: payload.items.length > 0 && payload.totalCount > 0 ? removeDuplicates([...state.storiesList, ...payload.items], 'timeLineID') : [], storiesLastDateTicks: payload.lastDateTicks, storiesTotalCount: payload.totalCount
    }
}



function addStory(state, payload) {
    return {
        ...state, storiesList: [payload, ...state.storiesList], storiesTotalCount: state.storiesTotalCount + 1
    }
}

function toggleFlag(state, payload) {
    return {
        ...state, [payload.type]: payload.status
    }
}

function setAllowedHomeworkActions(state, payload) {
    let allowedActions = state.homeworkAllowedActions

    for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
            allowedActions[key] = payload[key]
        }
    }

    return {
        ...state, homeworkAllowedActions: allowedActions
    }
}

function updateStudents(state, payload) {
    let updatedStudents = state.classStudents
    payload.list.forEach((id, i) => {
        let index = updatedStudents.findIndex(uStudent => uStudent.studentId === id)
        if (index !== -1) {
            if (payload.action.includes('homework')) {
                updatedStudents[index].haveHomework = payload.action === 'homework_added'
            }
            if (payload.action.includes('attendance')) {
                let status = payload.action === 'attendance_added'
                updatedStudents[index].attended = status
            }
            if (payload.action.includes('badge')) {
                if (payload.action === 'badge_added') {
                    updatedStudents[index].badgeCount = updatedStudents[index].badgeCount + 1
                }
            }
        }

    });

    return {
        ...state, classStudents: updatedStudents
    }
}

function setFirebaseToken(state, payload) {
    localStorage.setItem('__fb', payload)
    return {
        ...state, firebaseToken: payload
    }
}

function listNotifications(state, payload) {
    return {
        ...state, allNotifications: payload
    }
}

function setSectionTitle(state, payload) {
    return {
        ...state, sectionTitle: payload
    }
}

function appendInput(state, payload) {
    let id = state.inputsList.length === 0 ? 1 : state.inputsList[state.inputsList.length - 1].id + 1
    console.log('reducer id', id)
    return {
        ...state, inputsList: state.inputsList.concat({ id: id, value: '', validate: false })
    }
}

function removeInput(state, payload) {
    let index = state.inputsList.findIndex((item) => item.id === payload)
    let list = state.inputsList
    console.log('remove input index', index)
    list.splice(index, 1)
    return {
        ...state, inputsList: list
    }
}

function removeLastInput(state, payload) {
    let index = state.inputsList.findIndex((item) => item.id === payload)
    console.log(index)
    console.log('state', state.inputsList)
    return {
        ...state, inputsList: state.inputsList.filter((item) => item.id !== payload)
    }
}



function removeAllInputs(state) {
    return {
        ...state, inputsList: []
    }
}

function editInputValue(state, payload) {
    let index = state.inputsList.findIndex((item) => item.id === payload.item.id)
    state.inputsList[index] = { id: payload.item.id, value: payload.value }
    return {
        ...state
    }
}

function setActiveSubject(state, payload) {
    return {
        ...state, activeSubject: payload
    }
}

function setQuizType(state, payload, mode) {
    let appendedQuiz = state.appendedQuiz ? state.appendedQuiz : [];
    if (mode == 'append') appendedQuiz.push(payload);
    else if (mode == 'update') {
        let selectedgQuiz = appendedQuiz.findIndex(x => x.id === payload.id);
        if (payload.sectionQuestions) payload.isNewSection = false;
        else payload.isNewQuestion = false
        appendedQuiz.splice(selectedgQuiz, 1, payload)
    } else if (mode === 'empty') {
        appendedQuiz = []
    } else if (mode === 'sorting') {
        appendedQuiz = payload
    } else if (mode === 'duplicate') {
        let selectedgQuizD = appendedQuiz.findIndex(x => x.id === payload.id);
        let duplicatedQuiz = { ...payload };
        duplicatedQuiz.id = uuidv4();

        if (duplicatedQuiz.sectionQuestions) {
            let sectionQuestions = [];
            payload.sectionQuestions.map(item => {
                let newItem = item
                newItem.id = null;
                newItem.id = uuidv4();
                sectionQuestions.push(newItem)
            });
            duplicatedQuiz.sectionQuestions = sectionQuestions;
            duplicatedQuiz.isNewSection = true
        }
        else duplicatedQuiz.isNewQuestion = true;

        appendedQuiz.splice(selectedgQuizD, 0, duplicatedQuiz);
    } else {
        let remainingQuiz = appendedQuiz.filter(x => x.id !== payload.id);
        appendedQuiz = remainingQuiz
    }
    return {
        ...state, appendedQuiz: appendedQuiz
    }
}

function setStudentsList(state, payload) {
    return {
        ...state, studentsList: payload
    }
}

function setActiveEditor(state, payload) {
    return {
        ...state, activeEditor: payload
    }
}

function setActiveTab(state, payload) {
    return {
        ...state, activeTab: payload
    }
}

function setCurrentClass(state, payload) {
    return {
        ...state, currentClass: payload
    }
}

function setCurrentQuiz(state, payload) {
    return {
        ...state, currentQuiz: payload
    }
}

function setQuestionTitle(state, payload){
    return {
        ...state, questionTitle: payload
    }
}

export default reducer
