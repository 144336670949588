export const SET_LOCALE = 'SET_LOCALE'
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS'
export const LIST_USER_NOTIFICATIONS = 'LIST_USER_NOTIFICATIONS'
export const PREPEND_NEW_NOTIFICATION = 'PREPEND_NEW_NOTIFICATION'
export const SET_NOTIFICATION_STATUS = 'SET_NOTIFICATION_STATUS'
export const SET_NOTIFICATIONS_COUNTER = 'SET_NOTIFICATIONS_COUNTER'
export const SET_UPLOADING_STATUS = 'SET_UPLOADING_STATUS'
export const SHOW_EDITOR_UPLOADER = 'SHOW_EDITOR_UPLOADER'
export const SHOW_MATH_MODAL = 'SHOW_MATH_MODAL'
export const LOG_EVENT = 'LOG_EVENT'
export const UPDATE_EVENT_DURATION = 'UPDATE_EVENT_DURATION'

export default {
    SET_LOCALE,
    UPDATE_CONNECTION_STATUS,
    LIST_USER_NOTIFICATIONS,
    SET_NOTIFICATION_STATUS,
    PREPEND_NEW_NOTIFICATION,
    SET_NOTIFICATIONS_COUNTER,
    SET_UPLOADING_STATUS,
    UPDATE_EVENT_DURATION,
    LOG_EVENT,
    SHOW_EDITOR_UPLOADER,
    SHOW_MATH_MODAL
}