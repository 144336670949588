export const SET_SPECIAL_NOTE_VISIBILITY = 'SET_SPECIAL_NOTE_VISIBILITY'
export const LIST_CLASSROOMS = 'LIST_CLASSROOMS'
export const LIST_CLASS_STUDENTS = 'LIST_CLASS_STUDENTS'
export const UPDATE_STUDENTS = 'UPDATE_STUDENTS'
export const LIST_STORIES = 'LIST_STORIES'
export const ADD_STORY = 'ADD_STORY'
export const TOGGLE_FLAG = 'TOGGLE_FLAG'
export const SET_ALLOWED_HOMEWORK_ACTIONS = 'SET_ALLOWED_HOMEWORK_ACTIONS'
export const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN'
export const LIST_ALL_NOTIFICATIONS = 'LIST_ALL_NOTIFICATIONS'
export const SET_SECTION_TITLE = 'SET_SECTION_TITLE'
export const SET_ACTIVE_SUBJECT = 'SET_ACTIVE_SUBJECT'
export const SET_QUIZ_TYPE = 'SET_QUIZ_TYPE'
export const SET_STUDENTS_LIST = 'SET_STUDENTS_LIST'
export const SET_CLASSROOMS_LIST = 'SET_CLASSROOMS_LIST'
export const APPEND_INPUT = 'APPEND_INPUT' 
export const REMOVE_INPUT = 'REMOVE_INPUT'
export const REMOVE_ALL_INPUTS = 'REMOVE_ALL_INPUTS'
export const EDIT_INPUT_VALUE= 'EDIT_INPUT_VALUE'
export const SET_ACTIVE_EDITOR = 'SET_ACTIVE_EDITOR'
export const SET_ACTIVE_NAVIGATION_TAB = 'SET_ACTIVE_NAVIGATION_TAB'
export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS'
export const SET_CURRENT_QUIZ = 'SET_CURRENT_QUIZ'
export const REMOVE_LAST_INPUT='REMOVE_LAST_INPUT'
export const SET_QUESTION_TITLE = 'SET_QUESTION_TITLE'

export default {
    SET_SPECIAL_NOTE_VISIBILITY,
    LIST_CLASSROOMS,
    LIST_CLASS_STUDENTS,
    UPDATE_STUDENTS,
    LIST_STORIES,
    ADD_STORY,
    TOGGLE_FLAG,
    SET_ALLOWED_HOMEWORK_ACTIONS,
    SET_FIREBASE_TOKEN,
    LIST_ALL_NOTIFICATIONS,
    SET_SECTION_TITLE,
    SET_ACTIVE_SUBJECT, 
    SET_QUIZ_TYPE,
    SET_ACTIVE_SUBJECT,
    SET_STUDENTS_LIST,
    SET_CLASSROOMS_LIST,
    APPEND_INPUT,
    REMOVE_INPUT,
    REMOVE_ALL_INPUTS,
    EDIT_INPUT_VALUE,
    SET_ACTIVE_EDITOR,
    SET_ACTIVE_NAVIGATION_TAB,
    SET_CURRENT_CLASS,
    SET_CURRENT_QUIZ,
    REMOVE_LAST_INPUT,
    SET_QUESTION_TITLE,
}