/* eslint-disable no-throw-literal */

import store from '../src/store'
import { responseMessages } from './resources/responseMessages';
import { responseMessagesAR } from './resources/responseMessagesAR';
import { authLogout, authLogin } from './modules/Authintication/store/actions';

var state = store.getState()
var currentLocale = localStorage.getItem('currentLocale') ? localStorage.getItem('currentLocale') : state.common.currentLocale
var headers = new Headers()
var baseUrl = 'https://adminauthentication.scholigit.com'
// var baseUrl = 'https://adminauthentication.developmentyard.org'

function setToken(after){
    headers.set('Accept', 'application/json')
    headers.set('Content-type', 'application/json')
    var token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : window.sessionStorage.getItem('access_token')
    var expiryDate = localStorage.getItem('token_expiry') ? localStorage.getItem('token_expiry') : window.sessionStorage.getItem('token_expiry')
    var currentDateTime = new Date();
    var expiryDateTime = new Date(expiryDate);
    headers.set('Accept-Language', localStorage.getItem('currentLocale') === 'english' ? 'en-US' : 'ar-EG')
    headers.set('Timezone-Offset', new Date().getTimezoneOffset() / 60)
    if(token){
        if(expiryDate){
            if(expiryDateTime.getTime() < currentDateTime.getTime()){
                store.dispatch(refreshToken(token, (newToken) => {
                    headers.set('Authorization', `Bearer ${newToken}`)
                    after()
                }))
            }
            else {
                headers.set('Authorization', `Bearer ${token}`)
                after()
            }
        }
        else {
            headers.set('Authorization', `Bearer ${token}`)
            after()
        }
    }
    else {
        store.dispatch(authLogout())
        after()
    }
}

function refreshToken(token, callback) {
    let body = {
        token: token,
        refreshToken: localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : window.sessionStorage.getItem('refreshToken'),
        clientId: "FoundationAPI",
        clientSecret: "FoundationSecretKey"
    }
    return async (dispatch) => {
        const response = await fetch(baseUrl + '/api/Auth/Refresh',{headers, body: JSON.stringify(body), method: 'post'})
        await handleResponse(response, (res) => {
            dispatch(authLogin({
                token: res.data.accessToken,
                roles: res.data.roles,
                refreshToken: res.data.refreshToken,
                token_expiry: res.data.tokenExpiration,
                user: {name: res.data.name, image: res.data.image},
            }))
            callback(res.data.accessToken)
        }, (error) => {
            dispatch(authLogout())
        })
    }
}

async function handleResponse(response, onSuccess, onFailure) {
    var currentLocale = localStorage.getItem('currentLocale')
    try {
        if(navigator.onLine){
            if(!response.ok){
                const contentType = response.headers.get("content-type")
                const exception = await contentType && contentType.indexOf("application/json") !== -1 ?
                 response.json() : response.text()
                handleHttpErrors(exception, response.status, currentLocale)
            }
            else {
                const res = await response.json()
                if(res.responseCode === 200){
                    onSuccess({data: res.responseData, code: 200})
                }
                else {
                    let errors =
                      res.responseCode === 105
                        ? res.validationList.map((msg) =>
                            typeof msg === "string"
                              ? msg
                              : msg.validationMessage
                          )
                        : res.responseCode === 142 ? 
                            [res.responseMessage] : 
                            [
                                currentLocale === "english"
                                ? responseMessages[res.responseCode]
                                : responseMessagesAR[res.responseCode],
                            ];
                    throw { errors: errors, code: res.responseCode };
                }
            }
        }
        else {
            throw { errors: [currentLocale === 'ar' ? 
            'لا يوجد إتصال بالإنترنت, يرجى المحاولة لاحقًا' : 
            'No internet connection, please try again!'], code: 503 }
        }
    } catch (error) {
        onFailure(error)
    }
}

function handleHttpErrors(exception, code, currentLocale) {
    switch (code) {
        case 401:
            throw { errors: [currentLocale === 'ar' ? 'غير مصرح' : 'Not authorized'], code: code }
        case 404: 
            throw { errors: [currentLocale === 'ar' ? 'الصفحة/الخدمة غير موجودة' : 'Page/Service not found'], code: code}
        case 500: {
            throw { errors: [currentLocale === 'ar' ? 'خطأ داخلي بالخادم' : 'Internal server error'], code: code}
        }
        default:
            throw { errors: [exception.responseMessage ? exception.responseMessage : 
                currentLocale === 'ar' ? 'حدث خطأ ما, رجاء المحاولة مجددًا' : 'Something went wrong'], code: code }
    }
}

function timeoutPromise(ms, promise) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        throw { errors: ['Request Timeout'], code: 408 }
      }, ms);
      promise.then(
        (res) => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    })
}

export const getResource = async (route, onSuccess, onFailure) => {
    setToken(async () => {
        try {
            const response = await timeoutPromise(1000000, fetch(baseUrl + route, {headers}))
            await handleResponse(response, (data) => {
                onSuccess(data)
            }, (error) => {
                onFailure(error)
            })
        } catch(e) {
            if(e.code === 408){
                onFailure(e)
            }
            else {
                onFailure({ errors: ['Something went wrong'], code: 400 })
            }
        }
    })    
}

export const postResource = async (route, body, onSuccess, onFailure, isFormData) => {  
    setToken(async () => {
        isFormData && headers.delete('Content-type')
        try {
            const response = await timeoutPromise(1000000, fetch(baseUrl + route,{headers, body, method: 'post'}))
            await handleResponse(response, (data) => {
                onSuccess(data)
            }, (error) => {
                onFailure(error)
            })
        }
        catch {
            onFailure({ errors: ['Request Timeout'], code: 408 })
        }
    })
}

export const patchResource = async (route, body, onSuccess, onFailure) => {  
    setToken(async () => {
        try {
            const response = await timeoutPromise(1000000, fetch(baseUrl + route,{headers, body, method: 'patch'}))
            await handleResponse(response, (data) => {
                onSuccess(data)
            }, (error) => {
                onFailure(error)
            })
        } catch {
            onFailure({ errors: ['Request Timeout'], code: 408 })
        }
    })
}

export const deleteResource = async (route, onSuccess, onFailure) => {  
    setToken(async () => {
        try {
            const response = await timeoutPromise(1000000, fetch(baseUrl + route,{headers, method: 'delete'}))
            await handleResponse(response, (data) => {
                onSuccess(data)
            }, (error) => {
                onFailure(error)
            })
        } catch {
            onFailure({ errors: ['Request Timeout'], code: 408 })
        }
    })
}